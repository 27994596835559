.styles-01{
    margin-top: 5vh; font-weight: 400;
}
.styles-02{
    margin-top: 5vh; font-weight: 400;
}
.input-01{
    border-left: 0px;
}
.styles-03{
    border-left: 0px; height: 15vh;
}
.form-control:focus{
    outline: none;
    box-shadow:none;
    border: 1px solid #ced4da;
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.04);
    border-radius: .25rem;
}

.card .card-header {
    background-color: #fff;
    border-bottom: none;
}