.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  z-index: 100;
  top: 0;
  right: 0;
}
.backdrop:focus {
  outline: none;
  outline-width: 0px;
}
  