body {
  margin: 0;
  font-family: 'Poppins',   'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #6b440d;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sticky-header {
  position: fixed;
  top: 0;
  z-index: 100;
  background-color: #F9F9FF;
}

.div-body{
  margin-top: 5.5rem;
  /* width: 100vw; */
}

.body-inner-div {
  width: 77.3vw;
  margin: auto;
  padding-bottom: 6rem;
}
@media screen and (max-width: 640px) {
  .div-body{
    margin-top: 5rem;
  }
}
@media screen and (max-width: 500px) {
  .div-body{
    margin-top: 2.7rem;
  }
  .body-inner-div{
    width: 97%;
    overflow-x: hidden;
  }
}
